import React from 'react'
import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import ContactUs from '../components/Info/Contact'

function Contact() {
    return(
        <>
        <Header />
        <ContactUs />
        <Footer />  
        </>
    )
}

export default Contact
import React from 'react'
import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import DagoraInfo from '../components/Info/DagoraInfo'
import Join from '../components/Join/Join'
import Team from '../components/Team/Team'
import Tree from '../assets/building.webp'


function About() {
    return(
        <>
        <Header />
        <DagoraInfo />
        <Join />
        <Team />
        <Footer />
        </>
    )
}

export default About
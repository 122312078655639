import React from 'react'


function Footer() {
    return (
        <footer className="md:flex justify-evenly h-40 md:h-20 border-black border-t-2 bg-DAgora-Blue">
            <div className="md:flex justify-center items-center ">
                <h3 className="text-sm md:p-6 p-2">
                    🛠 with ❤️ by <a href="https://twitter.com/0xOrphan">Dadlessnsad</a> & <a href="https://twitter.com/raynemang"> Knowledgeable Idiot</a> — with DecentrAgora
                    decentralizing everything!
                </h3>
            </div>
            {/* <div className='md:flex justify-end items-center '>
                <h3 className="md:text-sm md:p-6">Built with: Open Libraries</h3>
                <h3 className="md:text-sm md:p-6">Powered by: <a href="https://universe.xyz/" >Universe XYZ</a></h3>
            </div> */}
            <div className='flex items-center justify-center'>
                <h3 className="md:text-sm md:p-6">Built with: Open Libraries</h3>
                <h3 className="md:text-sm md:p-6">Powered by: <a target='_blank' href="https://universe.xyz/" >Universe XYZ</a></h3>
            </div>
        </footer>
    )
}

export default Footer
import React from "react";
import Mpic from "../../assets/Mpic.webp";
import Rpic from '../../assets/Rpic.webp'
import Tpic from '../../assets/Tpic.webp'
import Apic from '../../assets/Apic.webp'
import Epic from "../../assets/Egod.webp"
import Anpic from "../../assets/Anpic.webp"

function Team() {
  return (
    <div
      id="team"
      className="h-full pb-12 md:pt-10 md:px-96 bg-gradient-to-b backdrop-blur-lg from-DAgora-Main to-DAgora-Blue "
    >
      <div className="flex items-center justify-center py-8">
        <h1 className="font-Poiret-One font-medium text-center text-4xl md:text-6xl  pb-4">
          The Syendrion
        </h1>
      </div>


      <div className="flex justify-center items-center p-4 md:py-24">
        <div className="mx-1 md:mx-8">
          <a target='blank' href="https://twitter.com/leclair_malik">
            <img
              src={Mpic}
              height={150}
              width={150}
              className=" hover:animate-pulse rounded-full"
            />
          </a>
          <h3 className="text-center font-Poiret-One font-semibold text-lg md:text-2xl">
            Moemies
          </h3>
        </div>
        <div className="mx-1 md:mx-8">
          <a target='blank' href="https://twitter.com/raynemang">
            <img
              src={Rpic}
              height={150}
              width={150}
              className="rounded-full hover:animate-pulse"
            />
          </a>
          <h3 className="text-center font-Poiret-One font-semibold text-lg md:text-2xl">
            Rayne
          </h3>
        </div>
        <div className="mx-1 md:mx-8">
          <a target='blank' href="https://twitter.com/0xOrphan">
            <img
              src={Tpic}
              height={150}
              width={150}
              className="rounded-full hover:animate-pulse"
            />
          </a>
          <h3 className="text-center font-Poiret-One font-semibold text-lg md:text-2xl">
            Tyler
          </h3>
        </div>
      </div>


      <div className="flex justify-center items-center p-4 md:pb-12">
        <div className="mx-1 md:mx-8">
          <a target='blank' href="https://twitter.com/Boss_Chaudhary">
            <img
              src={Apic}
              height={150}
              width={150}
              className="rounded-full hover:animate-pulse"
            />
          </a>
          <h3 className="text-center font-Poiret-One font-semibold text-lg md:text-2xl">
            Amit
          </h3>
        </div>
        <div className="mx-1 md:mx-8">
          <a target='blank' href="https://twitter.com/0xegod">
            <img
              src={Epic}
              height={150}
              width={150}
              className="rounded-full hover:animate-pulse"
            />
          </a>
          <h3 className="text-center font-Poiret-One font-semibold text-lg md:text-2xl">
            Nic
          </h3>
        </div>
        <div className="mx-1 md:mx-8">
          <a target='blank' href="https://twitter.com/grasspunch88">
            <img
              src={Anpic}
              height={150}
              width={150}
              className="rounded-full hover:animate-pulse"
            />
          </a>
          <h3 className="text-center font-Poiret-One font-semibold text-lg md:text-2xl">
            Anthony
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Team;

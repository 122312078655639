import React from 'react' 
import Logo from '../../assets/DagoraLogoLarge.webp'
import {Link} from 'react-router-dom'

function Header() {
    return(
        <header className="flex items-center justify-between bg-DAgora-Blue md:p-8 z-50">
            <div className="flex items-center">
            <Link to="/">
                <img src={Logo} height={80} width={80} />
            </Link>
            </div>
            <div className="flex items-center">
                <a target='_blank' href='https://discord.gg/tbmqwGAEhp' className="text-white md:text-2xl md:px-4 px-2 hover:text-DAgora-Pink">Discord</a>
                <Link to="/contactus" className="text-white md:px-4 px-2 md:text-2xl hover:text-DAgora-Pink">Contact us</Link>
            </div>
        </header>
    )
}


export default Header
import React from 'react'
import {Link} from 'react-router-dom'
import HeaderHome from '../components/Layout/HeaderHome'
import Footer from '../components/Layout/Footer'
import '../index.css'

function Home() {
    return(
        <>
        <HeaderHome />
        <div className='flex justify-center items-center min-h-screen bg-center bg-no-repeat bg-cover bg-gradient-to-b from-DAgora-Blue to-DAgora-Main '>
          <div className="mb-32">
            <h1 className='font-medium text-6xl md:text-9xl font-megrim drop-shadow-2xl text-white '>DecentrAgora</h1>
            <div className='flex justify-evenly py-16'>
              <Link 
                to="/about" 
                className="
                  z-10 
                  bg-DAgora-Blue
                  p-4
                  rounded-md
                  w-40 
                  text-center
                  text-white
                  md:text-xl
                  text-lg
                  drop-shadow-2xl
                  hover:bg-DAgora-Blue-Lite
                "
              >
                Learn More
              </Link>
              <a 
                target='_blank' 
                href="https://discord.gg/tbmqwGAEhp" 
                className='z-10 
                  z-10 
                  bg-DAgora-Blue
                  p-4
                  rounded-md
                  w-40 
                  text-center
                  text-white
                  md:text-xl
                  text-lg
                  drop-shadow-2xl
                  hover:bg-DAgora-Blue-Lite
                '
              >
                Discord
              </a>
            </div>
          </div>
        </div>
        <Footer />
        </>
    )
}

export default Home